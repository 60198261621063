// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AQK4eq{border-bottom:1px solid var(--bm-theme-bgGrayDarkColor,rgba(0,0,0,.1));padding-bottom:40px}.Dk5jjD{font-size:20px;line-height:26.6px;margin-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/providers/Booking/BookingLightbox/Accommodations/Results/BookingExceedCapacityResult.scss"],"names":[],"mappings":"AAEA,QAEI,sEAAA,CADA,mBACA,CAGJ,QAEI,cAAA,CACA,kBAAA,CAFA,kBAEA","sourcesContent":["@use \"../../../../../scss/colors\" as *;\r\n\r\n.root {\r\n    padding-bottom: 40px;\r\n    border-bottom: 1px solid $bm-bgGrayDarkColor;\r\n}\r\n\r\n.title {\r\n    margin-bottom: 10px;\r\n    font-size: 20px;\r\n    line-height: 26.6px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AQK4eq`,
	"title": `Dk5jjD`
};
export default ___CSS_LOADER_EXPORT___;
