// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mbVsJt{align-items:baseline;background:var(--label-bg,var(--bm-theme-bgGrayLightColor,rgba(0,0,0,.04)));border:var(--label-border);border-radius:var(--label-borderRadius,10px);color:var(--label-color,var(--bm-theme-fontBlackColor,rgba(0,0,0,.87)));display:inline-flex;font-size:var(--label-fontSize,inherit);gap:var(--label-gap);line-height:var(--label-lineHeight,inherit);outline:var(--label-outline);padding:4px 10px}`, "",{"version":3,"sources":["webpack://./src/components/EntityCard/HourlyObject/HourlyObjectsCard.scss"],"names":[],"mappings":"AAGA,QAEI,oBAAA,CAMA,2EAAA,CAEA,0BAAA,CAHA,4CAAA,CADA,uEAAA,CALA,mBAAA,CAGA,uCAAA,CAKA,oBAAA,CAJA,2CAAA,CAMA,4BAAA,CARA,gBAQA","sourcesContent":["@use \"../../../scss/colors\" as *;\r\n@use \"../../../scss/theme\" as *;\r\n\r\n.label {\r\n    display: inline-flex;\r\n    align-items: baseline;\r\n    padding: 4px 10px;\r\n    font-size: var(--label-fontSize, inherit);\r\n    line-height: var(--label-lineHeight, inherit);\r\n    color: var(--label-color, $bm-fontBlackColor);\r\n    border-radius: var(--label-borderRadius, 10px);\r\n    background: var(--label-bg, $bm-bgGrayLightColor);\r\n    gap: var(--label-gap);\r\n    border: var(--label-border);\r\n    outline: var(--label-outline);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `mbVsJt`
};
export default ___CSS_LOADER_EXPORT___;
