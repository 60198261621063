// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eRT5Yr{font-size:18px;line-height:24px;padding:10px 0 14px}.OfxLNT{font-size:18px;line-height:24px;padding:20px 0 14px}`, "",{"version":3,"sources":["webpack://./src/components/RequisitesLightbox/RequisitesLightbox.scss"],"names":[],"mappings":"AAAA,QACI,cAAA,CACA,gBAAA,CACA,mBAAA,CAGJ,QACI,cAAA,CACA,gBAAA,CACA,mBAAA","sourcesContent":[".headerHotelInfo {\r\n    font-size: 18px;\r\n    line-height: 24px;\r\n    padding: 10px 0 14px;\r\n}\r\n\r\n.headerBankAccount {\r\n    font-size: 18px;\r\n    line-height: 24px;\r\n    padding: 20px 0 14px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHotelInfo": `eRT5Yr`,
	"headerBankAccount": `OfxLNT`
};
export default ___CSS_LOADER_EXPORT___;
