// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes LjOfah{0%{opacity:.25}to{opacity:1}}.YklTqt{animation:LjOfah .5s ease infinite alternate;background-color:hsla(0,0%,91%,.5);border-radius:.2em;display:inline-block;height:1.2em;max-width:20em;min-width:2em;vertical-align:top;width:100%}button .YklTqt{min-width:5em}`, "",{"version":3,"sources":["webpack://./src/common/components/TranslationLoader/TranslationLoader.scss"],"names":[],"mappings":"AAAA,kBACI,GACI,WAAA,CAGJ,GACI,SAAA,CAAA,CAIR,QASI,4CAAA,CAFA,kCAAA,CACA,kBAAA,CAPA,oBAAA,CAKA,YAAA,CAFA,cAAA,CADA,aAAA,CADA,kBAAA,CAGA,UAIA,CAGJ,eACI,aAAA","sourcesContent":["@keyframes loading {\r\n    0% {\r\n        opacity: .25;\r\n    }\r\n\r\n    100% {\r\n        opacity: 1;\r\n    }\r\n}\r\n\r\n.root {\r\n    display: inline-block;\r\n    vertical-align: top;\r\n    min-width: 2em;\r\n    max-width: 20em;\r\n    width: 100%;\r\n    height: 1.2em;\r\n    background-color: rgba(231, 231, 231, 50%);\r\n    border-radius: .2em;\r\n    animation: loading 500ms infinite alternate ease;\r\n}\r\n\r\nbutton .root {\r\n    min-width: 5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `YklTqt`,
	"loading": `LjOfah`
};
export default ___CSS_LOADER_EXPORT___;
