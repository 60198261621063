// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bmveD3{align-items:center;display:flex}.AmdWAJ{margin:0 2px}`, "",{"version":3,"sources":["webpack://./../packages/components/accommodation/Accommodation.scss"],"names":[],"mappings":"AAAA,QAEI,kBAAA,CADA,YACA,CAGJ,QACI,YAAA","sourcesContent":[".root {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.plus {\r\n    margin: 0 2px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `bmveD3`,
	"plus": `AmdWAJ`
};
export default ___CSS_LOADER_EXPORT___;
