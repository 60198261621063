// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ktH9S2{color:rgba(0,0,0,.87);display:flex;flex-flow:column nowrap;gap:10px}.bofzCb{display:flex;flex-flow:row wrap;gap:8px}`, "",{"version":3,"sources":["webpack://./../packages/components/roomCategory/parameters/RoomCategoryParameters.scss","webpack://./../packages/styles/colors.scss","webpack://./../packages/styles/flex.scss"],"names":[],"mappings":"AAGA,QAGI,qBCDa,CCJb,YAAA,CACA,uBAAA,CACA,QDEa,CDIjB,QEFI,YAAA,CACA,kBAAA,CACA,OFC6B","sourcesContent":["@use \"@skbkontur/hotel-styles/flex\";\r\n@use \"@skbkontur/hotel-styles/colors\";\r\n\r\n.root {\r\n    @include flex.gap-vertical(10px);\r\n\r\n    color: colors.$fontBlackColor;\r\n}\r\n\r\n.labels {\r\n    @include flex.gap-horizontal(8px, wrap);\r\n}","/* Usage: https://guides.kontur.ru/principles/base/color/ */\r\n\r\n/* Should be synced with /pms/src/css/colors.styl and Colors.ts */\r\n\r\n$fontBlackActiveColor: #3d3d3d;\r\n$fontBlackColor: rgba(0, 0, 0, 87%);    // #222\r\n$fontGrayColor: rgba(0, 0, 0, 54%);     // #757575\r\n$fontDisabledColor: rgba(0, 0, 0, 32%); // #adadad\r\n\r\n/* Gray bg */\r\n$bgGrayLightColor: rgba(0, 0, 0, 4%); // #F6F6F6\r\n$bgGrayColor: rgba(0, 0, 0, 8%);      // #EBEBEB\r\n$bgGrayDarkColor: rgba(0, 0, 0, 10%); // #E6E6E6\r\n\r\n/* Accent */\r\n$bgYellowLightColor: #FFEEC2;\r\n$bgYellowColor: #FCB73E;\r\n$fontYellowColor: #EF8B17;\r\n\r\n/* Error */\r\n$bgRedLightColor: #FFEBEB;\r\n$bgRedColor: #FE4C4C;\r\n$fontRedColor: #CC2626;\r\n\r\n/* Success */\r\n$bgGreenLightColor: #C7F9CC;\r\n$bgGreenColor: #26AD50;\r\n$fontGreenColor: #1C8A3F;\r\n\r\n/* Blue */\r\n$bgBlueLightColor: #CDEDFF;\r\n$bgBlueColor: #2291FF;\r\n$fontBlueColor: #1874cf;\r\n\r\n$whiteColor: #FFF;","@mixin gap-vertical($gap, $wrap: nowrap) {\r\n    display: flex;\r\n    flex-flow: column $wrap;\r\n    gap: $gap;\r\n}\r\n\r\n@mixin gap-horizontal($gap, $wrap: nowrap) {\r\n    display: flex;\r\n    flex-flow: row $wrap;\r\n    gap: $gap;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ktH9S2`,
	"labels": `bofzCb`
};
export default ___CSS_LOADER_EXPORT___;
