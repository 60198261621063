// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PCgFfy{width:235px}`, "",{"version":3,"sources":["webpack://./src/providers/BookingOrder/Fields/OrderFormPhone.scss"],"names":[],"mappings":"AAAA,QACI,WAAA","sourcesContent":[".tooltip {\r\n    width: 235px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `PCgFfy`
};
export default ___CSS_LOADER_EXPORT___;
