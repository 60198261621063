// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SzKmrK{padding-right:17px;position:relative}.MR2LsC{position:absolute;right:0;top:0}`, "",{"version":3,"sources":["webpack://./src/components/PlacementParameters/PlacementParameters.scss"],"names":[],"mappings":"AAAA,QAEI,kBAAA,CADA,iBACA,CAGJ,QACI,iBAAA,CACA,OAAA,CACA,KAAA","sourcesContent":[".iconRow {\r\n    position: relative;\r\n    padding-right: 17px;\r\n}\r\n\r\n.icon {\r\n    position: absolute;\r\n    right: 0;\r\n    top: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconRow": `SzKmrK`,
	"icon": `MR2LsC`
};
export default ___CSS_LOADER_EXPORT___;
