// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VeX3tR{align-items:center;background:var(--label-bg,rgba(0,0,0,.04));border:var(--label-border);border-radius:var(--label-borderRadius,10px);box-shadow:var(--label-boxShadow);color:var(--label-color,rgba(0,0,0,.87));display:inline-flex;font-size:var(--label-fontSize,14px);gap:var(--label-gap,16px);line-height:var(--label-lineHeight,20px);max-width:300px;outline:var(--label-outline);overflow:hidden;padding:4px 10px;white-space:nowrap}.VeX3tR.tPEoE4{border-radius:calc(var(--label-borderRadius, 10px)/2);height:20px}.VeX3tR.jIoffb{background-color:transparent;height:auto;padding:0}`, "",{"version":3,"sources":["webpack://./../packages/components/label/Label.scss"],"names":[],"mappings":"AAIA,QAEI,kBAAA,CAGA,0CAAA,CAQA,0BAAA,CADA,4CAdW,CAgBX,iCAAA,CAHA,wCAAA,CAVA,mBAAA,CAOA,oCAAA,CALA,yBAAA,CAMA,wCAAA,CAHA,eAAA,CASA,4BAAA,CARA,eAAA,CAHA,gBAAA,CAMA,kBAKA,CAEA,eAEI,qDAAA,CADA,WACA,CAGJ,eACI,4BAAA,CAEA,WAAA,CADA,SACA","sourcesContent":["@use \"@skbkontur/hotel-styles/colors\" as *;\r\n\r\n$borderRadius: var(--label-borderRadius, 10px);\r\n\r\n.label {\r\n    display: inline-flex;\r\n    align-items: center;\r\n    gap: var(--label-gap, 16px);\r\n    padding: 4px 10px;\r\n    background: var(--label-bg, $bgGrayLightColor);\r\n    max-width: 300px;\r\n    overflow: hidden;\r\n    font-size: var(--label-fontSize, 14px);\r\n    line-height: var(--label-lineHeight, 20px);\r\n    white-space: nowrap;\r\n    color: var(--label-color, $fontBlackColor);\r\n    border-radius: $borderRadius;\r\n    border: var(--label-border);\r\n    box-shadow: var(--label-boxShadow);\r\n    outline: var(--label-outline);\r\n\r\n    &.small {\r\n        height: 20px;\r\n        border-radius: calc($borderRadius / 2);\r\n    }\r\n\r\n    &.textMode {\r\n        background-color: transparent;\r\n        padding: 0;\r\n        height: auto;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `VeX3tR`,
	"small": `tPEoE4`,
	"textMode": `jIoffb`
};
export default ___CSS_LOADER_EXPORT___;
