import ReactDOM from "react-dom";
import styles from "../../WidgetApp.scss";
import {WidgetSettingsRoots} from "../../settings/WidgetSettingsRoots";
import {WidgetClass} from "../../Widget";
import WidgetThemeProvider from "../WidgetTheme/WidgetThemeProvider";
import {IWidgetRefresh} from "../../settings/WidgetCommon";
import {KnownActualWidgetSettings} from "../../settings/WidgetInstanceSettings";

export interface IWidgetContainerAndRefProps {
    hotelId: string;
    widget: WidgetClass;
}

export const withWidgetContainerAndRefHOC = <TProps extends IWidgetContainerAndRefProps>(
    Component: React.ComponentType<TProps>
) => (
    // @ts-expect-error Generic type loss for props with ref
    React.memo(React.forwardRef((props: TProps, ref: React.ForwardedRef<IWidgetRefresh>) => {
        const {hotelId, widget: {id, settings}} = props;
        const {type, appearance, theme} = settings;

        // eslint-disable-next-line react/hook-use-state
        const [, setRefreshCount] = React.useState<number>(1);

        React.useImperativeHandle(ref, () => ({
            refresh() {
                setRefreshCount(refreshCount => refreshCount + 1);
            }
        }));

        // eslint-disable-next-line no-console
        console.log(`Widget render ${id}`);

        const container = WidgetSettingsRoots.createWidgetRoot(hotelId, type, appearance);

        if (!container) return null;

        return (
            ReactDOM.createPortal(
                <div className={styles.container}>
                    <WidgetThemeProvider widgetTheme={theme} type={type as KnownActualWidgetSettings["type"]}>
                        <Component {...props}/>
                    </WidgetThemeProvider>
                </div>,
                container
            )
        );
    }))
);
