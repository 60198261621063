import {IWidgetSettingsAppearance} from "../settings/WidgetSettings";
import {WidgetType} from "../settings/WidgetType";
import {WidgetValidationResult} from "./WidgetValidationResult";

const describeProvided = (type: WidgetType) => `Provided container at widget type "${type}"`;
const containerExample: IWidgetSettingsAppearance = {container: null};
const containerFields = Object.keys(containerExample);

export type EnumValue<Type> = Type[keyof Type];

export const WidgetValidationMessages = {
    [WidgetValidationResult.ContainerIsEmpty]:
        (type: WidgetType) => (
            `${describeProvided(type)} is not defined` +
            `\nYou need to provide fields: "${containerFields.join(", ")}"`
        ),

    [WidgetValidationResult.ContainerIncorrectType]:
        (type: WidgetType) => `${describeProvided(type)} have an incorrect type`,

    [WidgetValidationResult.ContainerNotFound]:
        (type: WidgetType) => `${describeProvided(type)} have correct type but not found in DOM`,

    [WidgetValidationResult.ContainerIsHidden]:
        (type: WidgetType) => `${describeProvided(type)} is hidden at the moment of KonturWidget starts`,

    [WidgetValidationResult.EnumError]:
        <T>(variableName: string, enumValue: EnumValue<T>, enumObject: T) => (
            `Provided ${variableName} have an incorrect value "${String(enumValue as string)}" ` +
            `but waiting some value of "${Object.values(enumObject).join(" | ")}"`
        ),

    [WidgetValidationResult.Ok]:
        () => "",
};
